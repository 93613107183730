import { Stack } from '@/core/blocks';
import { HtmlMarkup, Typography } from '@/core/ui';
import type { ICompetitionStats } from '@/types';
import { useTranslation } from 'react-i18next';
import { BestScore } from './best-score';
import { GameData } from './game-data';
import { GoalData } from './goal-data';

export const CompetitionStats = ({ stats }: { stats: ICompetitionStats }) => {
  const { t } = useTranslation('translation');

  return (
    <div className='w-full h-screen px-28 bg-content1'>
      <div className='flex flex-col h-full space-y-16'>
        <div className='flex flex-row space-x-11'>
          <Typography className='text-chalk text-3xl font-semibold tracking-[1.5px] leading-[28.5px] mt-[71px]'>
            {t('competition.statistics')}
          </Typography>
          <img
            className='h-[480px] w-[940px] object-contain'
            src={stats.competition_image?.path}
            alt={stats.competition_image?.caption || ''}
          />
          <div className='flex items-end'>
            <HtmlMarkup className='text-chalk text-[26px] leading-[29.9px] tracking-[1.3px] max-w-[480px]'>
              {stats.statistic_text}
            </HtmlMarkup>
          </div>
        </div>
        <div className='flex flex-row w-full px-24 pt-16 pb-24 bg-graphite/85 rounded-[46px] justify-between'>
          <div className='flex flex-col space-y-12'>
            <GameData gameData={stats.statistic_games} />
          </div>
          <div className='flex items-end'>
            <Stack upperClass='w-[5px] h-[137px]' lowerClass='w-[5px] h-[73px]' />
          </div>
          <div className='flex flex-col space-y-12'>
            <GoalData
              totalGame={
                stats.statistic_games?.defeats + stats.statistic_games?.draws + stats.statistic_games?.wins
              }
              goalData={stats.statistic_goals}
            />
          </div>
          <div className='flex items-end'>
            <Stack upperClass='w-[5px] h-[137px]' lowerClass='w-[5px] h-[73px]' />
          </div>
          <div className='flex flex-col space-y-8'>
            <BestScore goalScorer={stats.statistic_goal_scorers} />
          </div>
        </div>
      </div>
    </div>
  );
};
