import type { IModality, IPicture } from './modality';

export enum EMember {
  SQUAD = 1,
  TECH_TEAM_MEMBER = 2,
}

export interface IPresident {
  id: number;
  name: string;
  profile_image: IPicture;
  full_body_image: IPicture;
  // logo: IPicture;
  // startDate: Date | string;
  // endDate: Date | string;
  // highlights: Array<{ title: string; date: Date | string }>;
}

export interface IPlayer {
  id: number;
  name: string;
  description: string;
  player_image: IPicture;
  position: string;
  extra_info: Array<{ title: string; content: string }>;
  has_competition_details: boolean;
}

export interface ITechTeamMember {
  id: number;
  name: string;
  description: string;
  member_image: IPicture;
  role: string;
  extra_info: Array<{ title: string; content: string }>;
  has_competition_details: boolean;
}

export interface INationalTeam {
  id: number;
  name: string;
  gender: string;
  modality: IModality;
}
