import { Typography } from '@/core/ui';
import type { ICompetitionStats } from '@/types';
import { useTranslation } from 'react-i18next';

interface IProps {
  totalGame: number;
  goalData: ICompetitionStats['statistic_goals'];
}

const GoalItem = ({ average, scored, text }: { average: number; scored: number; text: string }) => {
  const { t } = useTranslation('translation');
  return (
    <div className='flex flex-row space-x-6'>
      <div className='bg-olive w-[90px] h-[90px] flex justify-center items-center rounded-2xl'>
        <Typography className='text-chalk text-[56px] font-bold'>{scored}</Typography>
      </div>
      <div className='flex flex-col justify-center'>
        <Typography className='break-keep leading-[26px] text-[26px] text-chalk tracking-[1.82px] uppercase font-semibold'>
          {text}
        </Typography>
        <Typography className='text-lg leading-[18px] text-chalk uppercase tracking-[1.26px]'>
          {t('competition.stats.goal.avg', { average })}
        </Typography>
      </div>
    </div>
  );
};

export const GoalData = ({ goalData, totalGame = 1 }: IProps) => {
  const { conceded = 0, scored = 0 } = goalData;
  const { t } = useTranslation('translation');

  return (
    <>
      <Typography className='text-3xl text-chalk tracking-[1.5px] font-semibold'>
        {t('competition.stats.goal.goals')}
      </Typography>
      <div className='flex flex-col space-y-[30px]'>
        <GoalItem
          scored={scored}
          text={t('competition.stats.goal.goalScored')}
          average={scored / totalGame}
        />
        <GoalItem
          scored={conceded}
          text={t('competition.stats.goal.goalConceded')}
          average={conceded / totalGame}
        />
      </div>
    </>
  );
};
