import type { EMember } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface CompetitionState {
  member: { id: number; type: EMember } | null;
  memberModal: boolean;
  modality: string;
  appTitle: string;
}

const initialState: CompetitionState = {
  member: null,
  memberModal: false,
  modality: '',
  appTitle: '',
};

export const globalSlice = createSlice({
  name: 'competition',
  initialState,
  reducers: {
    setMember: (state, action: PayloadAction<CompetitionState['member']>) => {
      state.member = action.payload;
      state.memberModal = true;
    },
    setAppTitle: (state, action: PayloadAction<CompetitionState['appTitle']>) => {
      state.appTitle = action.payload;
    },
    setModality: (state, action: PayloadAction<CompetitionState['modality']>) => {
      state.modality = action.payload;
    },
    setMemberModal: (state, action: PayloadAction<CompetitionState['memberModal']>) => {
      state.memberModal = action.payload;
      if (!action.payload) {
        state.member = null;
      }
    },
  },
});

export const { setMember, setAppTitle, setModality, setMemberModal } = globalSlice.actions;

export default globalSlice.reducer;
