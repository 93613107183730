import { Icon } from '@/core/icon';
import {
  Carousel,
  CarouselContent,
  type CarouselContextProps,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  LazyImage,
  LazyVideo,
  Typography,
} from '@/core/ui';
import { cn } from '@/lib';
import type { IPicture } from '@/types';
import { useState } from 'react';

type ItemProps = {
  selectedIndex: number;
  slide: IPicture;
  index: number;
};

export const VideoItem = ({ slide, index, selectedIndex }: ItemProps) => {
  const [playing, setPlaying] = useState(false);

  const toggle = () => {
    setPlaying((e) => !e);
  };

  return (
    <div className='h-full w-full relative'>
      <LazyVideo
        inView={true}
        thumbnail={slide.thumbnail}
        style={{ outline: 'none' }}
        width={'100%'}
        height={'95%'}
        playing={playing && selectedIndex === index}
        url={slide.path}
      />

      {/* <video autoPlay controls className='h-full outline-none w-full'>
        <source src={slide.url} type={slide.mimeType} />
        <Typography className='text-2xl mb-12 leading-[30px] tracking-[1.2px] text-chalk'>
          Sorry, your browser doesn't support videos.
        </Typography>
      </video> */}
      {/* <img src={slide.url} alt={slide.caption} className='w-full h-[80vh]' /> */}
      <div
        className={cn(
          'bg-gradient-to-b from-transparent via-black/80 to-black',
          'absolute h-44 flex justify-center items-end bottom-0 w-full',
        )}
      >
        <Typography className='text-2xl mb-12 leading-[30px] tracking-[1.2px] text-chalk'>
          {slide.caption}
        </Typography>
      </div>
      <button type='button' onClick={toggle} className='outline-none absolute bottom-10 left-16'>
        <Icon className='w-28 h-28' name={playing ? 'PAUSE' : 'PLAY'} />
      </button>
    </div>
  );
};

export const ImageItem = ({ slide }: ItemProps) => {
  return (
    <div className='flex justify-center items-center relative'>
      <LazyImage
        inView={true}
        // inView={slidesInView.indexOf(index) > -1}
        src={slide.path}
        alt={slide.caption || ''}
        className='w-full h-[80vh]'
        imgClass='object-cover object-top'
      />
      <div
        className={cn(
          'bg-gradient-to-b from-transparent via-black/80 to-black',
          'absolute h-44 flex justify-center items-end bottom-0 w-full',
        )}
      >
        <Typography className='text-2xl mb-12 leading-[30px] tracking-[1.2px] text-chalk'>
          {slide.caption}
        </Typography>
      </div>
    </div>
  );
};

export const GalleryItem = (props: ItemProps) => {
  if (props.slide.mime_type.includes('video')) {
    return <VideoItem {...props} />;
  }

  return <ImageItem {...props} />;
};

export const GallerySlider = ({ slides }: { slides: IPicture[] }) => {
  const renderContent = (data: CarouselContextProps) =>
    slides.map((slide, index) => (
      <CarouselItem
        key={slide.path}
        className={cn({
          'basis-[85%]': slides.length > 1,
          'basis-[100%] w-full': slides.length < 2,
        })}
      >
        <GalleryItem selectedIndex={data.dots.selectedIndex} index={index} slide={slide} />
      </CarouselItem>
    ));

  return (
    <Carousel
      opts={{
        align: 'center',
        startIndex: 0,
        // dragThreshold: 2000,
        dragFree: false,
      }}
      className='w-full'
    >
      <CarouselContent wrapperClass='rounded-3xl'>
        {renderContent as never}
        {/* {slides.map((slide, index) => (
          <CarouselItem
            key={slide.path}
            className={cn({
              'basis-[85%]': slides.length > 1,
              'basis-[100%] w-full': slides.length < 2,
            })}
          >
            <GalleryItem index={index} slide={slide} />
          </CarouselItem>
        ))} */}
      </CarouselContent>
      {slides.length > 1 && (
        <>
          <CarouselNext className='hover:bg-danger disabled:hidden' />
          <CarouselPrevious className='hover:bg-danger disabled:hidden' />
          <CarouselDots className='absolute -bottom-16' />
        </>
      )}
    </Carousel>
  );
};
