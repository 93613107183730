import { Icon } from '@/core/icon';
import { Button } from '@/core/ui';
import { cn } from '@/lib/utils';
import type { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react';
// import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { createContext, forwardRef, useCallback, useContext, useEffect, useState } from 'react';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
};

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

export type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  dots: UseDotButtonType;
  slidesInView: number[];
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

export function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const CarouselScrollBar = () => {
  const {
    dots: { scrollSnaps, selectedIndex },
  } = useCarousel();

  return (
    <div className={cn('absolute bottom-0 w-full h-[5px] bg-content1')}>
      <div
        style={{
          width: 1920 / (scrollSnaps.length || 5),
          marginLeft: (1920 / scrollSnaps.length) * selectedIndex || 0,
          animationDuration: '800ms',
          transitionDuration: '800ms',
          transitionProperty: 'all',
        }}
        className={cn('h-[5px] bg-chalk ease-out')}
      >
        {' '}
      </div>
    </div>
  );
};

const CarouselDots = forwardRef<HTMLButtonElement, React.ComponentProps<'button'>>(
  ({ className, ...props }, ref) => {
    const { orientation, dots } = useCarousel();
    const { selectedIndex, scrollSnaps, onDotButtonClick } = dots;

    return (
      <div
        className={cn(
          'flex flex-row justify-center w-full items-center space-x-5',
          { 'absolute top-1/2 -right-8 translate-x-1/2 rotate-90': orientation === 'vertical' },
          className,
        )}
      >
        {scrollSnaps.map((_, index) => (
          <div key={index}>
            <button
              ref={ref}
              type='button'
              onClick={() => onDotButtonClick(index)}
              className={cn('w-4 rounded-full h-4 bg-content2', {
                'bg-danger': index === selectedIndex,
              })}
              {...props}
            />
          </div>
        ))}
      </div>
    );
  },
);
CarouselDots.displayName = 'CarouselDots';

const Carousel = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(
  ({ orientation = 'horizontal', opts, setApi, plugins = [], className, children, ...props }, ref) => {
    // plugins.push(
    //   WheelGesturesPlugin({ forceWheelAxis: orientation === 'horizontal' ? 'y' : 'y', skipSnaps: true }),
    // );
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
    const [slidesInView, setSlidesInView] = useState<number[]>([]);

    const onInit = useCallback((emblaApi: EmblaCarouselType) => {
      setScrollSnaps(emblaApi.scrollSnapList());
    }, []);

    const onDotButtonClick = useCallback(
      (index: number) => {
        if (!api) return;
        api.scrollTo(index);
      },
      [api],
    );

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setSelectedIndex(api.selectedScrollSnap());
      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
      setSlidesInView((slidesInView) => {
        if (slidesInView.length === emblaApi.slideNodes().length) {
          emblaApi.off('slidesInView', updateSlidesInView);
        }
        const inView = emblaApi.slidesInView().filter((index) => !slidesInView.includes(index));
        return slidesInView.concat(inView);
      });
    }, []);

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onInit(api);
      onSelect(api);
      updateSlidesInView(api);
      api.on('slidesInView', updateSlidesInView);
      api.on('reInit', onSelect);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      return () => {
        api?.off('select', onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          slidesInView,
          dots: {
            selectedIndex,
            scrollSnaps,
            onDotButtonClick,
          },
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn('relative', className)}
          aria-roledescription='carousel'
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = 'Carousel';

const CarouselContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    wrapperClass?: string;
    children: string | JSX.Element | JSX.Element[] | ((data: CarouselContextProps) => any);
  }
>(({ wrapperClass, className, children, ...props }, ref) => {
  const data = useCarousel();
  const { carouselRef, orientation } = data;

  return (
    <div ref={carouselRef} className={cn('overflow-hidden', wrapperClass)}>
      <div
        ref={ref}
        className={cn('flex', orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col', className)}
        {...props}
      >
        {typeof children === 'function' ? children?.(data) : children}
      </div>
    </div>
  );
});
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { orientation } = useCarousel();

    return (
      <div
        ref={ref}
        role='group'
        aria-roledescription='slide'
        className={cn(
          'min-w-0 shrink-0 grow-0 basis-full',
          orientation === 'horizontal' ? 'pl-4' : 'pt-4',
          className,
        )}
        {...props}
      />
    );
  },
);
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
  ({ className, children, variant = 'outline', size = 'icon', ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();

    return (
      <Button
        ref={ref}
        className={cn(
          'absolute h-24 w-24 rounded-full bg-danger border-none flex items-center justify-center',
          'disabled:bg-danger/80',
          orientation === 'horizontal'
            ? '-left-12 top-1/2 -translate-y-1/2'
            : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
          className,
        )}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      >
        {children ?? <Icon name='CHEVRON_LEFT' className='h-7 w-7' />}
        <span className='sr-only'>Previous slide</span>
      </Button>
    );
  },
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
  ({ className, children, variant = 'outline', size = 'icon', ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();

    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          'absolute  h-24 w-24 rounded-full bg-danger border-none flex items-center justify-center',
          '',
          orientation === 'horizontal'
            ? '-right-12 top-1/2 -translate-y-1/2'
            : '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
          className,
        )}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...props}
      >
        {children ?? <Icon name='CHEVRON_RIGHT' className='h-7 w-7' color='white' />}
        <span className='sr-only'>Next slide</span>
      </Button>
    );
  },
);

export {
  CarouselDots,
  type CarouselApi,
  Carousel,
  CarouselScrollBar,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
};
