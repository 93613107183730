import { envVars } from '@/config';
import { competitions, memberDetail, playerDetail } from '@/mocks';
import type {
  IGetCompetitionByIdReq,
  IGetCompetitionByIdRes,
  IGetCompetitionReq,
  IGetCompetitionRes,
  IGetPlayerByIdReq,
  IGetPlayerByIdRes,
  IGetTechMemberByIdReq,
  IGetTechMemberByIdRes,
} from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';
import { setAppTitle, setModality } from './slice';

export const competitionApi = createApi({
  reducerPath: 'competitionApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['competition'],
  endpoints: (builder) => ({
    getCompetitions: builder.query<IGetCompetitionRes['data'], IGetCompetitionReq>({
      query: ({ modalityId }) => ({
        url: `/hall-of-champions/competitions?modality_id=${modalityId}`,
        isProtected: false,
        forceMock: false,
        mockData: {
          message: 'Competitions fetched Successfully',
          data: { competitions, titles_count: 1, modality: 'Futebol' },
        },
      }),
      transformResponse: (response: IGetCompetitionRes) => response.data ?? {},
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        dispatch(setModality(''));
        dispatch(setAppTitle(''));
        const { data } = await queryFulfilled;
        dispatch(setModality(data.modality));
        dispatch(setAppTitle(data.app_title));
      },
    }),
    getCompetitionById: builder.query<IGetCompetitionByIdRes['data']['competition'], IGetCompetitionByIdReq>({
      query: ({ competitionId }) => ({
        url: `/hall-of-champions/competition/${competitionId}`,
        isProtected: false,
        forceMock: false,
        mockData: {
          message: 'Competition fetched Successfully',
          data: { competition: competitions.find((o) => o.id === competitionId) },
        },
      }),
      transformResponse: (response: IGetCompetitionByIdRes) => response.data?.competition ?? {},
    }),
    getPlayerDetail: builder.query<IGetPlayerByIdRes['data'], IGetPlayerByIdReq>({
      query: ({ competitionId, memberId }) => ({
        url: `/hall-of-champions/competition/${competitionId}/player/${memberId}`,
        isProtected: false,
        forceMock: false,
        mockData: {
          message: 'Player fetched Successfully',
          data: playerDetail,
        },
      }),
      transformResponse: (response: IGetPlayerByIdRes) => response.data ?? {},
    }),
    getTechMemberDetail: builder.query<IGetTechMemberByIdRes['data'], IGetTechMemberByIdReq>({
      query: ({ competitionId, memberId }) => ({
        url: `/hall-of-champions/competition/${competitionId}/technical-team-member/${memberId}`,
        isProtected: false,
        forceMock: false,
        mockData: {
          message: 'Player fetched Successfully',
          data: memberDetail,
        },
      }),
      transformResponse: (response: IGetTechMemberByIdRes) => response.data ?? {},
    }),
  }),
});

export const { useGetCompetitionsQuery, useLazyGetCompetitionByIdQuery } = competitionApi;
