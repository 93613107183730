import { ROUTES } from '@/config';
import { Stack, Trophy } from '@/core/blocks';
import { Typography } from '@/core/ui';
import { API } from '@/store/api';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { TrophyLoading } from './loading';

export const CompetitionList = () => {
  const { t, i18n } = useTranslation('translation');
  const { modalityId } = useParams();
  const { error, isLoading, isFetching, data } = API.competitionApi.useGetCompetitionsQuery(
    { modalityId: Number.parseInt(modalityId as string, 10), locale: i18n.language },
    { skip: !modalityId || !i18n.language },
  );

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !data) return <TrophyLoading />;

  return (
    <div className='absolute flex flex-row w-full px-24 space-x-56 bottom-0 overflow-scroll items-end'>
      <div className='flex flex-row'>
        <div className='flex flex-col items-center w-full space-y-4'>
          <Typography className='rotate-180 text-3xl font-semibold justify-end [writing-mode:vertical-lr]'>
            {t('home.trophyText', { trophyCount: data.titles_count })}
          </Typography>
          <Stack />
        </div>
        <div className='w-[1px] h-[716px] shrink-0 border-[1px] border-content1'> </div>
      </div>

      {data.competitions.map((competition, idx) => (
        <motion.div
          key={competition.id}
          initial={{ y: 500, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 * idx, duration: 1 }}
        >
          <Link
            to={`${ROUTES.HOME.MAIN}/${modalityId}/competition/${competition.id}`}
            className='flex justify-center items-center'
          >
            <Trophy data={competition} />
          </Link>
        </motion.div>
      ))}
    </div>
  );
};
