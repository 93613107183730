import { HtmlMarkup, Typography } from '@/core/ui';
import type { ICompetitionMembers } from '@/types';

export const President = ({ data }: { data: ICompetitionMembers['president_info'] }) => {
  const { president, president_text } = data;
  return (
    <div className='flex flex-row space-x-[35px] ml-10'>
      <img
        src={president?.full_body_image?.path}
        alt={president?.full_body_image?.caption || ''}
        className='ml-2 w-[251px] h-[768px]'
      />

      <div className='flex flex-col mt-4'>
        <img src='/gallery/president-logo.svg' alt={'logo'} className='ml-1 h-auto w-16' />
        {/* <img src={president.logo.path} alt={president.logo.caption} className='ml-1 h-auto w-16' /> */}
        <div className='max-w-[337px] mt-8 ml-1'>
          <Typography className='text-chalk text-[80px] leading-[76px] tracking-[4px] font-semibold'>
            {president?.name}
          </Typography>
        </div>
        <div className='flex flex-row py-14'>
          <div className='w-[41px] h-[8.5px] bg-danger' />
          <div className='w-[41px] h-[8.5px] bg-olive' />
        </div>
        <div className='flex flex-col max-w-[386px] space-y-10'>
          <HtmlMarkup className='text-chalk text-[26px] tracking-[1.3px] leading-[29.9px]'>
            {president_text}
          </HtmlMarkup>
        </div>
      </div>
    </div>
  );
};
