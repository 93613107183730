import { HtmlMarkup, Typography } from '@/core/ui';
import type { ICompetitionHighlight } from '@/types';
import { useTranslation } from 'react-i18next';

export const CompetitionGallery = ({ highlight }: { highlight: ICompetitionHighlight['highlight'] }) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <div className='flex flex-row space-x-[125px]'>
        <div className='mt-[71px]'>
          <Typography className='text-chalk text-3xl leading-[28.5px] tracking-[1.5px] font-semibold'>
            {t('competition.gallery.text')}
          </Typography>
        </div>
        <img
          className='h-[30rem] w-[30rem] object-cover'
          src={highlight.media_1?.path}
          alt={highlight.media_1?.caption || ''}
        />
      </div>
      <div className='flex flex-row space-x-[28px]'>
        <img
          className='h-[504px] w-[480px] object-cover'
          src={highlight.media_2?.path}
          alt={highlight.media_2?.caption || ''}
        />
        <div className='max-w-[150px]'>
          <HtmlMarkup className='text-chalk text-[26px] leading-[29.9px] tracking-[1.3px]'>
            {highlight.media_2?.caption}
          </HtmlMarkup>
        </div>
      </div>
      {/* <div className='absolute -right-[32rem] bottom-0'>
        <img className='h-full' src={highlight.media_3?.path} alt={highlight.media_3?.caption || ''} />
      </div> */}
    </>
  );
};
