import { cn } from '@/lib';
import type { IGetCompetitionRes } from '@/types';

export interface IProps {
  data: IGetCompetitionRes['data']['competitions'][0];
}

export const Trophy = ({ data }: IProps) => {
  return (
    <div className='flex flex-col space-y-7 h-full items-center w-36'>
      <img className='brightness-0' src={data.trophy_image.path} alt={data.trophy_image.caption || ''} />
      <span
        className={cn(
          'line-[27.6px] uppercase tracking-[1.2px] text-2xl',
          'font-united-sans font-medium text-content1',
          'text-3xl tracking-[2.1px]',
        )}
      >
        {data.year}
      </span>
      <div className='shrink-0 h-64 w-[1px] border-[1px] border-content1'> </div>
    </div>
  );
};
