import type { IPlayer, ITechTeamMember } from '@/types';

export const players: IPlayer[] = [
  {
    id: 1,
    name: 'Cristiano Ronlado Ronlado Ronlado',
    player_image: {
      path: 'https://admin.fpf.gemadigital.com/uploads/ronaldo.png',
      mime_type: 'image/png',
      caption: 'ronaldo',
    },
    position: 'Avançado',
    has_competition_details: false,
    extra_info: [
      {
        title: 'GOLOS',
        content: '3',
      },
      {
        title: 'ASSISTENCIAS',
        content: '10',
      },
      {
        title: 'MINUTOS',
        content: '435',
      },
    ],
    description:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit Amet, consectetur adipiscing elit, sed do eiusmod tempoLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing.</p>',
  },
];

export const techTeamMembers: ITechTeamMember[] = [
  {
    id: 1,
    name: 'Cristiano Ronlado',
    member_image: {
      path: 'https://admin.fpf.gemadigital.com/uploads/ronaldo.png',
      mime_type: 'image/png',
      caption: 'ronaldo',
    },
    role: 'Avançado',
    has_competition_details: false,
    extra_info: [
      {
        title: 'GOLOS',
        content: '3',
      },
      {
        title: 'ASSISTENCIAS',
        content: '10',
      },
      {
        title: 'MINUTOS',
        content: '435',
      },
    ],
    description:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit Amet, consectetur adipiscing elit, sed do eiusmod tempoLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing.</p>',
  },
];

export const playerDetail = {
  competition: {
    id: 1,
    name: 'LIGA DAS NAÇÕES',
    year: 2009,
    trophy_image: {
      path: 'https://admin.fpf.gemadigital.com/uploads/trophy.png',
      mime_type: 'image/png',
      caption: 'Trophy',
    },
  },
  player: {
    id: 1,
    name: 'Ronlado',
    player_image: {
      path: 'https://admin.fpf.gemadigital.com/uploads/ronaldo.png',
      mime_type: 'image/png',
      caption: 'ronaldo',
    },
    position: 'Forward',
    has_competition_details: true,
    description:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit Amet, consectetur adipiscing elit, sed do eiusmod tempoLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing.</p>',
    extra_info: [
      {
        title: 'GOLOS',
        content: '3',
      },
      {
        title: 'ASSISTENCIAS',
        content: '10',
      },
      {
        title: 'MINUTOS',
        content: '435',
      },
    ],
  },
};

export const memberDetail = {
  competition: {
    id: 1,
    name: 'LIGA DAS NAÇÕES',
    year: 2009,
    trophy_image: {
      path: 'https://admin.fpf.gemadigital.com/uploads/trophy.png',
      mime_type: 'image/png',
      caption: 'Trophy',
    },
  },
  member: {
    id: 1,
    name: 'Fernando santos',
    member_image: {
      path: 'https://admin.fpf.gemadigital.com/uploads/caoch.png',
      mime_type: 'image/png',
      caption: 'iage',
    },
    role: 'Coach',
    has_competition_details: true,
    description:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit Amet, consectetur adipiscing elit, sed do eiusmod tempoLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing.</p>',
    extra_info: [],
  },
};
