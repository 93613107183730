import { Typography } from '@/core/ui';
import type { ICompetitionHighlight, ICompetitionMembers } from '@/types';
import { useTranslation } from 'react-i18next';
import { CompetitionGallery } from './gallery';
import { President } from './president';

interface IProps {
  president: ICompetitionMembers['president_info'];
  highlight: ICompetitionHighlight['highlight'];
}
export const Galleria = ({ president, highlight }: IProps) => {
  const { t } = useTranslation('translation');

  return (
    <div className='grid grid-cols-8 bg-content1 h-screen'>
      <div className='col-span-4 flex flex-col mt-[71px] ml-[97px] space-y-[93px]'>
        <Typography className='text-chalk text-3xl leading-[28.5px] tracking-[1.5px] font-semibold'>
          {t('competition.member.president')}
        </Typography>
        <President data={president} />
      </div>
      <div className='col-span-3 space-y-24 ml-[20px] overflow-hidden relative'>
        <CompetitionGallery highlight={highlight} />
      </div>
      <div className='col-span-1 flex justify-end items-center ml-[5rem]'>
        <img src='/gallery/game3.png' alt='Players-Playing' className='h-full' />
      </div>
    </div>
  );
};
