import { Typography } from '@/core/ui';
import type { IPlayer } from '@/types';

interface IProps {
  data: IPlayer[];
}

export const PlayerProfile = ({ data }: IProps) => {
  if (data.length > 1) {
    return (
      <div className='flex flex-col space-y-[25px] w-40 justify-start items-center'>
        {data.map((d) => (
          <div key={d.id} className='flex flex-row w-full space-x-4 justify-start items-center'>
            <img alt='' src='/trophyInfo/profile.png' className='bg-chalk rounded-full w-[45px] h-[45px]' />
            <Typography className='line-clamp-2 text-xl pt-1 text-chalk tracking-[1.4px] leading-[22px] uppercase max-w-36'>
              {d.name}
            </Typography>
          </div>
        ))}
      </div>
    );
  }

  const player = data[0];

  return (
    <div className='flex flex-col space-y-4 items-center'>
      <img
        alt=''
        src={player?.player_image?.path}
        className='bg-chalk mr-4 w-[141px] h-[141px] rounded-full'
      />
      <Typography className='text-center text-xl line-clamp-2 text-chalk tracking-[1.4px] uppercase font-semibold max-w-36'>
        {player?.name}
      </Typography>
    </div>
  );
};
